import React, {Suspense} from 'react';
import {Route, BrowserRouter, Routes, Navigate} from 'react-router-dom';
import Spin from "./components/Spin";
import Sync from "./components/Sync";
import GetKIZ from "./components/GetKIZ";
const Auth = React.lazy(() => import('./components/Auth'));
const Home = React.lazy(() => import('./components/Home'));

export const App = () => {
    return (
        <BrowserRouter>
            <Suspense fallback={<Spin />}>
                <Routes>
                    <Route exact path="/" element={<Auth />}/>
                    <Route exact path="/home" element={<Home />}/>
                    <Route exact path="/sync" element={<Sync />}/>
                    <Route exact path="/logout" element={<Navigate to={'/'} />} />
                    <Route exact path="/getKIZ" element={<GetKIZ />} />
                </Routes>
            </Suspense>
        </BrowserRouter>
    )
}

export default App

import React, {useEffect, useState} from 'react';
import {Button, Container, Spinner, Table, Toast, ToastContainer} from "react-bootstrap";
import {default as axios} from "axios";
import NavBar from "./NavBar";
import {checkAuth} from "./func";
import {useNavigate} from "react-router-dom";

const Sync = () => {
    const navigate = useNavigate()
    const [whoAmi, setWhoAmi] = useState([])
    const [tasks, setTasks] = useState([])
    const [errorToast, setErrorToast] = useState(false)
    const [errorText, setErrorText] = useState('')

    const cA = () => {
        checkAuth().then((res)=>{
            if (res){
                setWhoAmi(res.data)
            } else {
                axios.post('/api/v1/logout', {}).then(res => {
                    navigate('/')
                })
            }
        }).catch(err => {
            axios.post('/api/v1/logout', {}).then(res => {
                navigate('/')
            })
        })
    }

    const formatDate = (date) => {
        const newDate = new Date(date)
        return `${newDate.getDate() < 10 ? `0${newDate.getDate()}` : newDate.getDate()}.${newDate.getMonth()+1 < 10 ? `0${newDate.getMonth()+1}` : newDate.getMonth()+1}.${newDate.getFullYear()}г. ${newDate.getHours() < 10 ? `0${newDate.getHours()}` : newDate.getHours()}:${newDate.getMinutes() < 10 ? `0${newDate.getMinutes()}` : newDate.getMinutes()}`
    }

    useEffect(() => {
        syncBy()
        cA()
    }, [])

    const syncBy = () => {
        axios.get(`/api/v1/task`)
            .then(res => {
                console.log(res);
                console.log(res.data);
                if (res) {
                    setTasks(res.data)
                    res.data.map((task) => {
                            if (task.status === 'STARTED'){
                                setTimeout(() => syncBy(), 5000)
                            }
                    })
                }
            })
    }

    const syncAPI = () => {
        axios.post(`/api/v1/syncby`)
            .then(res => {
                console.log(res);
                console.log(res.data);
                if (res.status < 400) {
                    setTasks(res.data)
                    res.data.map((task) => {
                        if (task.status === 'STARTED'){
                            setTimeout(() => syncBy(), 5000)
                        }
                    })
                } else {
                    setErrorText(res?.data?.detail)
                    setErrorToast(true)
                    setTimeout(() => setErrorToast(false), 3000)
                }
            }).catch((err) => {
                setErrorText(err?.response?.data?.detail)
                setErrorToast(true)
                setTimeout(() => setErrorToast(false), 5000)
        })
    }

    console.log(tasks)
    return (
        <main>
            <NavBar whoAmi={whoAmi} />
            <Container className={"text-white pt-5"}>
                <h3>Синхронизация с Честным знаком</h3>
                <p className={"text-white-50"}>Импорт данных из честного знака в ваш аккаунт.</p>
                <Button variant={"warning"} className={"bi bi-check-square mt-2"} onClick={() => syncAPI()}> Начать синхронизацию</Button>
                <Table variant={"dark"} className={"mt-5"} striped bordered hover>
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Идентификатор задачи</th>
                        <th>Дата и время запуска</th>
                        <th>Статус</th>
                    </tr>
                    </thead>
                    <tbody>
                    {tasks.length > 0 ? tasks.map((task, index) => (
                        <tr key={task?.guid}>
                            <td>{index+1}</td>
                            <td>{task?.uuid_task}</td>
                            <td>{formatDate(task?.created)}</td>
                            <td className={task?.status === 'PENDING' ? 'text-info' : task?.status === 'STARTED' ? 'text-warning' : task?.status === 'SUCCESS' ? 'text-success' : task?.status === 'FAILURE' ?  "text-danger" : 'text-white'}>
                                {task?.status === 'PENDING' ? <i className="bi bi-arrow-repeat"></i> : task?.status === 'STARTED' ? <Spinner size={"sm"} animation={"grow"} variant="warning" /> : task?.status === 'SUCCESS' ? <i className={'bi bi-cloud-check-fill'}></i> : task?.status === 'FAILURE' ? <i className="bi bi-bug-fill"></i> : ''}
                                &nbsp;
                                {task?.status === 'PENDING' ? 'В очереди' : task?.status === 'STARTED' ? 'В процессе' : task?.status === 'SUCCESS' ? 'Завершён' : task?.status === 'FAILURE' ? 'Ошибка' : task?.status}
                            </td>
                        </tr>
                        ))
                        :
                        ''
                    }
                    </tbody>
                </Table>
                <ToastContainer className={'position-fixed top-0 end-0 me-3 mt-3'}>
                    <Toast
                        show={errorToast}
                        bg={'danger'}
                        id={'error'}
                    >
                        <Toast.Body className={'fw-bold text-white'}>{errorText}</Toast.Body>
                    </Toast>
                </ToastContainer>
            </Container>
        </main>
    );
};

export default Sync;
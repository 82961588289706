import React from 'react';
import {Container, Nav, Navbar, NavDropdown} from "react-bootstrap";
import logo from '../logo.svg';
import axios from "axios";
import {useNavigate} from "react-router-dom";

const NavBar = ({whoAmi}) => {
    const navigate = useNavigate()

    const logout = () => {
        axios.post('/api/v1/logout', {}).then(res => {
                navigate('/')
        })
    }

    return (
        <Navbar bg="dark" variant="dark">
            <Container>
                <Navbar.Brand href="/home" className={"text-danger fw-bolder fs-3"}>
                    <img height={30} style={{marginTop: "-8px"}} src={logo} alt={''}/> MexOPT
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link href="/home">Индивидуализация</Nav.Link>
                        <Nav.Link href="/getKIZ">Приёмка пустых КИЗ</Nav.Link>
                    </Nav>
                    <Nav className="ms-auto">
                        <NavDropdown align={"end"} menuVariant={"dark"} title={whoAmi?.fullName} id="collasible-nav-dropdown">
                            <NavDropdown.Item href="/sync">Синхронизация</NavDropdown.Item>
                            {/*<NavDropdown.Item href="/sett">Настройки</NavDropdown.Item>*/}
                            <NavDropdown.Divider />
                            <NavDropdown.Item onClick={logout}>Выйти</NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default NavBar;
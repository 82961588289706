import React, {useEffect, useState} from 'react';
import NavBar from "./NavBar";
import {checkAuth} from "./func";
import {useNavigate} from "react-router-dom";
import {Button, Container, ListGroup} from "react-bootstrap";
import axios from "axios";
import {CadesPluginer} from "@crpt/cades-pluginer";

const GetKIZ = () => {
    const navigate = useNavigate()
    const [whoAmi, setWhoAmi] = useState([])
    const [cert, setCert] = useState([])
    const [orders, setOrders] = useState([])

    const cA = () => {
        checkAuth().then((res)=>{
            if (res){
                setWhoAmi(res.data)
            } else {
                axios.post('/api/v1/logout', {}).then(res => {
                    navigate('/')
                })
            }
        }).catch(err => {
            axios.post('/api/v1/logout', {}).then(res => {
                navigate('/')
            })
        })
    }

    const test = (guid) => {
    axios.get(`/api/v1/orders/${guid}`).then(res => {
        console.log(res.data, cert.certificate)
        CadesPluginer.signMessage(res.data, cert.certificate, true).then(sign => {
            let df = {
                "signature": sign,
                "document": res.data,
                "documentType": "ACCEPTANCE"
            }
            axios.post('api/v1/orders/createkiz', df).then(res => {
                console.log(res)
            })
    })

    })}

    useEffect(() => {
        cA()

        CadesPluginer.getFinalCertsArray().then((data) => {
            data.map(dt => {
                if (dt.info[3] === localStorage.getItem("sign")){
                    setCert(dt)
                }
            })
        })

    }, [])

    useEffect(()=> {

        let url = "/api/v1/orders";

        axios.get(url).then(res => {

            setOrders(res.data)
        })
    }, [])

    return (
        <div>
        <NavBar whoAmi={whoAmi}  />

            <Container className={"mt-4 bg-transparent"}>
                <ListGroup variant={'dark'} className={"my-4"}>
                    {orders.length > 0? orders.map(order => (
                        <ListGroup.Item key={order?.guid} style={{backgroundColor: "#383B3F"}} className={"d-flex justify-content-between align-items-center text-light"}>
                            <div>Заказ {order?.cisCount} шт. от {new Intl.DateTimeFormat('ru', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(order?.creationDate)}</div>
                            <div><Button variant={'outline-warning'} onClick={() => test(order?.guid)}>Принять КИЗы</Button></div>
                        </ListGroup.Item>
                    ) ): "" }
                </ListGroup>
            </Container>

        </div>
    );
};

export default GetKIZ;
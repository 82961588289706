import {default as axios} from "axios";

export const checkAuth = () => {
    return new Promise((resolve, reject) => {
        axios.get('/api/v1/whoami')
            .then((data) => {
                    resolve(data)
                }
            )
            .catch((error) => {
                reject(error);
            })
    })
}
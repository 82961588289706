import React from 'react';

const Spin = () => {
    return (
        <div className="d-flex justify-content-center align-items-center min-vh-100 min-vw-100">
                    <div className="spinner-border text-success mx-auto my-auto" role="status">
                        <span className="sr-only"></span>
                    </div>
        </div>
    );
};

export default Spin;